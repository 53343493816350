import React from 'react'
import Layout from '../components/layout'
import Banner from '../components/partials/banner'
import { css } from '@emotion/core'
import useScript from '../hooks/useScript';

  
const PrivacyPage = () => {
  
  return <Layout title="Privacy Policy">
    <Banner />

    <div css={css`
      padding: 5rem 3rem;
      display: grid;
      grid-template-columns: minmax(1.2rem, 1fr) minmax(auto, 57ch) minmax(1.2rem, 1fr);
      justify-content: center;
      justify-items: center;
    `}>
      <div css={css`grid-column: 2;`}>
        <h1>Mastery Games Privacy Policy</h1>

        <p>Mastery Games collects the bare minimum of information for the services rendered and respects the privacy of users.</p>

        <h2>Information collected</h2>
        <p>By visiting this website or the various course landing pages you provide the following information as collected by basic aggregate analtyics:</p>
        <ul>
          <li>browser</li>
          <li>region</li>
          <li>device</li>
          <li>activity</li>
          <li>pages visited & duration</li>
        </ul>

        <p>When you sign up for a Mastery Games account you provide the following information:</p>
        <ul>
          <li>first name</li>
          <li>email address</li>
        </ul>

        <p>By progressing through a course you provide the following information:</p>
        <ul>
          <li>course progress and activity</li>
        </ul>

        <h2>Financial Information</h2>
        <p>Payments to Mastery Games are handled securely by <a href="https://stripe.com/">Stripe</a>. No financial information is stored by Mastery Games.</p>

        <h2>How your information is used</h2>
        <p>Your name and email are used solely for communication between Mastery Games and yourself. Your course progress/activity is used for unlocking levels as you progress, as well as improving the user experience of the products.</p>

        <h2>Privacy</h2>
        <p>All your information provided is secure and private. We do not sell or otherwise share your information to other parties in any way. Your information belongs to you. It can be exported and/or deleted at any time upon request.</p>

        <h2>Contact</h2>
        <p>Feel free to <a href="mailto:dave@mastery.games">reach out</a> if you have any questions.</p> <br/>
      </div>
    </div>

  </Layout>
}

export default PrivacyPage
